export const useAuthStore = defineStore('auth', () => {
  const refreshToken = ref('')
  const accessToken = ref('')
  const signedOut = ref(false)
  const refreshing = ref(false)
  const signingOut = ref(false)
  const { errorToast } = useToast()
  const { signOut } = useSignOut()
  const router = useRouter()

  function resetTokens() {
    refreshToken.value = ''
    accessToken.value = ''
  }

  const { data } = useAuth()
  watch(data, (data) => {
    accessToken.value = data?.accessToken ?? ''
    refreshToken.value = data?.refreshToken ?? ''
  }, { immediate: true })

  async function handleExpiredToken() {
    if (refreshing.value) {
      await new Promise<void>((resolve) => { // This promise is to wait until the token is refreshed
        const unwatch = watch(refreshing, (newVal) => {
          if (!newVal) {
            unwatch()
            resolve()
          }
        })
      })
      return true
    }

    refreshing.value = true
    const { signIn: handleRefreshToken } = useAuth()
    const response = await handleRefreshToken('refresh', {
      accessToken: accessToken.value,
      refreshToken: refreshToken.value,
      redirect: false,
    })

    if (!response || response.error) {
      await handleSessionExpired()
      return null
    }
    return true
  }

  async function handleSessionExpired() {
    if (signingOut.value)
      return

    signingOut.value = true
    await signOut({ redirect: false })
    router.push('/login')
    setTimeout(() => {
      errorToast('Session expired')
      signingOut.value = false
    }, 400)
  }

  return {
    refreshToken,
    accessToken,
    signedOut,
    handleExpiredToken,
    handleSessionExpired,
    resetTokens,
  }
})
