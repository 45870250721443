export default {
  root: ({ state }) => ({
    class: [
      'rounded-lg shadow-lg border-0',
      'max-h-[98vh] transform scale-100',
      'm-0 w-full md:w-[50vw]',
      'dark:border dark:border-primary-900/40',
      {
        'transition-none transform-none !w-screen !h-screen !max-h-full !top-0 !left-0': state.maximized,
      },
    ],
  }),
  header: {
    class: ['flex items-center justify-between shrink-0', 'bg-white text-gray-800 border-t-0  rounded-tl-lg rounded-tr-lg p-6', 'dark:bg-gray-900  dark:text-white/80', 'text-xl font-semibold'],
  },
  headerTitle: {
    class: 'font-bold text-2xl',
  },
  icons: {
    class: 'flex items-center gap-2',
  },
  closeButton: {
    class: [
      'flex items-center justify-center overflow-hidden relative',
      'w-8 h-8 text-gray-500 border-0 bg-transparent rounded-full transition duration-200 ease-in-out mr-2 last:mr-0',
      'hover:text-gray-700 hover:border-transparent hover:bg-gray-200',
      'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]', // focus
      'dark:hover:text-white/80 dark:hover:border-transparent dark:hover:bg-gray-800/80 dark:focus:shadow-[inset_0_0_0_0.2rem_rgba(147,197,253,0.5)]',
    ],
  },
  closeButtonIcon: {
    class: 'w-4 h-4 inline-block',
  },
  content: ({ state, instance }) => ({
    class: [
      'overflow-y-visible', //fix for preventing dropdown-list getting inside the dialog container
      'bg-white text-gray-700 text-lg p-6 pt-0',
      'dark:bg-gray-900  dark:text-white/80',
      {
        grow: state.maximized,
      },
      {
        'rounded-bl-lg rounded-br-lg': !instance.$slots.footer,
      },
    ],
  }),
  footer: {
    class: ['flex pt-2 gap-3 shrink-0 justify-end align-center', 'border-t-0 bg-white text-gray-700 px-6 pb-6 text-right rounded-b-lg', 'dark:bg-gray-900  dark:text-white/80'],
  },
  mask: ({ props, state }) => ({
    class: [
      // Transitions
      'transition',
      'duration-200',
      { 'p-5': !state.maximized },

      // Background and Effects
      { 'bg-black/40': props.modal, 'backdrop-blur-sm': props.modal },
    ],
  }),
  transition: ({ props }) => {
    return props.position === 'top'
      ? {
        enterFromClass: 'opacity-0 scale-75 translate-x-0 -translate-y-full translate-z-0',
        enterActiveClass: 'transition-all duration-200 ease-out',
        leaveActiveClass: 'transition-all duration-200 ease-out',
        leaveToClass: 'opacity-0 scale-75 translate-x-0 -translate-y-full translate-z-0',
      }
      : props.position === 'bottom'
        ? {
          enterFromClass: 'opacity-0 scale-75 translate-y-full',
          enterActiveClass: 'transition-all duration-200 ease-out',
          leaveActiveClass: 'transition-all duration-200 ease-out',
          leaveToClass: 'opacity-0 scale-75 translate-x-0 translate-y-full translate-z-0',
        }
        : props.position === 'left' || props.position === 'topleft' || props.position === 'bottomleft'
          ? {
            enterFromClass: 'opacity-0 scale-75 -translate-x-full translate-y-0 translate-z-0',
            enterActiveClass: 'transition-all duration-200 ease-out',
            leaveActiveClass: 'transition-all duration-200 ease-out',
            leaveToClass: 'opacity-0 scale-75  -translate-x-full translate-y-0 translate-z-0',
          }
          : props.position === 'right' || props.position === 'topright' || props.position === 'bottomright'
            ? {
              enterFromClass: 'opacity-0 scale-75 translate-x-full translate-y-0 translate-z-0',
              enterActiveClass: 'transition-all duration-200 ease-out',
              leaveActiveClass: 'transition-all duration-200 ease-out',
              leaveToClass: 'opacity-0 scale-75 opacity-0 scale-75 translate-x-full translate-y-0 translate-z-0',
            }
            : {
              enterFromClass: 'opacity-0 scale-75',
              enterActiveClass: 'transition-all duration-200 ease-out',
              leaveActiveClass: 'transition-all duration-200 ease-out',
              leaveToClass: 'opacity-0 scale-75',
            }
  },
}
