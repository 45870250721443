import type { FetchOptions } from 'ofetch'
import type { AsyncData } from 'nuxt/app'
import queryString from 'query-string'
import { storeToRefs } from 'pinia'
import { HttpMethods } from '@/enums/httpMethods'
import { toSnakeCase } from '@/helpers'
import { useAuthStore } from '@/stores/authStore'

export function useApi() {
  const data: Ref<ApiResponse<GenericObjectI>> | GenericObjectI = ref(null)
  const authStore = useAuthStore()
  const { accessToken } = storeToRefs(authStore)

  const state = reactive({
    error: null,
    loading: false,
  })

  const { errorToast } = useToast()

  async function get(
    endpoint: string,
    params: GenericObjectI = {},
    options?: FetchOptions,
  ) {
    return await request(endpoint, { params, method: HttpMethods.get, ...options })
  }

  // I can't name this 'delete' because is a reserved word
  async function deletion(
    endpoint: string,
    params: GenericObjectI = {},
    options?: FetchOptions,
  ) {
    return await request(endpoint, { params, method: HttpMethods.delete, ...options })
  }

  async function post(
    endpoint: string,
    body: GenericObjectI = {},
    options?: FetchOptions,
  ) {
    return await request(endpoint, { method: HttpMethods.post, body, ...options })
  }

  async function put(
    endpoint: string,
    body: GenericObjectI = {},
    options?: FetchOptions,
  ) {
    return await request(endpoint, { method: HttpMethods.put, body, ...options })
  }

  async function request(endpoint: string, options?: FetchOptions): Promise<any> {
    state.loading = true

    try {
      const config = useRuntimeConfig()
      const apiUrl = config.public.apiUrl
      const settings: FetchOptions = {
        ...options,
        method: options?.method as HttpMethods,
        headers: {
          Authorization: `Bearer ${accessToken.value}`,
        },
      }

      let url = `${apiUrl}/${endpoint}`

      if (
        (!settings.method || settings.method === HttpMethods.get)
        && settings.params
      ) {
        const formattedParams = `${queryString.stringify(
          { ...toSnakeCase(settings.params) },
          { arrayFormat: 'bracket' },
        )}`
        url = `${url}${formattedParams && `?${formattedParams}`}`
        delete settings.params
      }
      const response = (await $fetch(url, settings)) as AsyncData<
        ApiResponse<GenericObjectI>,
        ApiResponseErrorI
      >

      data.value = response?.data ?? response
      state.loading = false

      return response
    }
    catch (error: any) {
      if (
        error?.data?.api_code === 'token_expired'
        || error?.data?.api_code === 'invalid_token'
        || error.response?.status === 401) {
        if (await authStore.handleExpiredToken())
          return await request(endpoint, options)
        // Retry request if token was refreshed
      }
      else {
        state.error = error
        errorToast()
        throw error
      }
    }
  }

  return { get, post, put, deletion, ...toRefs(state) }
}
