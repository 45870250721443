export default {
  icon: {
    class: '!text-3xl mr-3',
  },
  pcrejectbutton: {
    root: {
      class: [
        'relative transform active:scale-95 transition-transform',
        'items-center inline-flex text-center align-bottom justify-center',
        'py-3 px-4 text-base leading-none',
        'rounded-lg',
        'text-surface-500 dark:text-surface-300',
        'bg-transparent border border-surface-300 hover:bg-surface-300/20 hover:border-surface-400/60',
        'hover:bg-surface-300/20',
      ],
    },
  },
  pcacceptbutton: {
    root: {
      class: [
        'relative transform active:scale-95 transition-transform',
        'items-center inline-flex text-center align-bottom justify-center',
        'py-3 px-4 text-base leading-none',
        'rounded-lg',
        'text-white dark:text-surface-900',
        'bg-red-700 dark:bg-red-400',
        'border border-red-700 dark:border-red-400',
        'hover:bg-red-600 dark:hover:bg-red-300 hover:border-red-600 dark:hover:border-red-300'
      ],
    },
  },
}
